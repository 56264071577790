import { useState } from 'react';
import styled from 'styled-components';

import ModalTitle from '../modal-title';
import { Cookies } from './cookies';
import { Privacy } from './privacy';
import { Terms } from './terms';

type TabState = 'terms' | 'privacy' | 'cookies';

const TocModal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabState>('terms');

  return (
    <Wrapper>
      <ModalTitle>Paperpal Terms and Policies</ModalTitle>
      <Tabs>
        <Tab tab="terms" {...{ activeTab, setActiveTab }}>
          Terms of Use
        </Tab>
        <Tab tab="privacy" {...{ activeTab, setActiveTab }}>
          Privacy Policy
        </Tab>
        <Tab tab="cookies" {...{ activeTab, setActiveTab }}>
          Cookies Policy
        </Tab>
      </Tabs>
      <Content>
        {activeTab === 'terms' && <Terms />}
        {activeTab === 'privacy' && <Privacy />}
        {activeTab === 'cookies' && <Cookies />}
      </Content>
    </Wrapper>
  );
};

export default TocModal;

const Wrapper = styled.div`
  max-height: calc(100vh - 10rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  padding: 4rem;
  flex: auto;
  overflow-y: auto;
  *:not(:last-child) {
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 2.8rem;
    line-height: 3rem;
    font-weight: 700;
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
  }
  ul {
    padding-left: 2rem;
  }
`;

const Tab: React.FC<{
  tab: TabState;
  activeTab: TabState;
  setActiveTab: (tab: TabState) => void;
}> = ({ activeTab, tab, children, setActiveTab }) => (
  <TabButton isActive={activeTab === tab} onClick={() => setActiveTab(tab)}>
    {children}
  </TabButton>
);

const TabButton = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0 4rem 1.6rem;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? '#0a235c' : '#7985a3')};
  border-bottom: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.palette.primary.main : '#f7f9fc')};
`;
